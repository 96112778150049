/* global axios */
import ApiClient from './ApiClient';

class TemplatesAPI extends ApiClient {
  constructor() {
    super('templates', { accountScoped: true });
  }

  getWhatsappTemplates(params) {
    return axios.get(this.url, { params });
  }

  create(params, options) {
    return axios.post(this.url, params, options);
  }
}

export default new TemplatesAPI();
