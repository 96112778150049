import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import TemplatesAPI from '../../api/templates';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    console.log("_state_state", _state)
    console.log("_state_state", _state.uiFlags)
    return _state.uiFlags;
  },
  getAttributes: _state => {
    return _state.records;
  },
  getWhatsappTemplates: _state => {

    console.log("getWhatsappTemplatesgetWhatsappTemplates", _state)
    return _state.records
    // .filter(
    //   record => record.attribute_model === attributeModel
    // );
  },
};

const changeData = (data) => {
  if(data.file) {
    const formData = new FormData();
    Object.keys(data).forEach(keyname => {
      console.log("keynamekeyname123", keyname, data[keyname])
      formData.append(`template[${keyname}]`, data[keyname]);
    });
    console.log("formDataformData123", formData)
    return formData
  } else {
    return data
  }
}

export const actions = {
  get: async function getWhatsappTemplates(paramss, params) {

    const { commit } = paramss
    console.log("paramsparamsparamsparams12322", paramss, params)


    const { id } = params
    commit(types.SET_TEMPLATE_UI_FLAG, { isFetching: true });
    try {
      const response = await TemplatesAPI.getWhatsappTemplates({ inbox_id: id });
      console.log("paramsparamsparamsparams1234", response, response.data)

      commit(types.SET_TEMPLATE, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_TEMPLATE_UI_FLAG, { isFetching: false });
    }
  },
  create: async function createAttribute({ commit }, attributeObj) {
    commit(types.SET_TEMPLATE_UI_FLAG, { isCreating: true });
    try {
      const payload = changeData(attributeObj)
      console.log("payloadpayloadpayload123", payload)
      var options = {}
      if(attributeObj.file) {
        options = { headers: { 'Content-Type': 'multipart/form-data' } }
      }
      const response = await TemplatesAPI.create(payload, options);
      commit(types.ADD_TEMPLATE, response.data);
      return response.data
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage);
    } finally {
      commit(types.SET_TEMPLATE_UI_FLAG, { isCreating: false });
    }
  },
  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.SET_TEMPLATE_UI_FLAG, { isUpdating: true });
    try {
      const response = await TemplatesAPI.update(id, updateObj);
      commit(types.EDIT_TEMPLATE, response.data);
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage);
    } finally {
      commit(types.SET_TEMPLATE_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_TEMPLATE_UI_FLAG, { isDeleting: true });
    try {
      await TemplatesAPI.delete(id);
      commit(types.DELETE_TEMPLATE, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_TEMPLATE_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_TEMPLATE_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.ADD_TEMPLATE]: MutationHelpers.create,
  [types.SET_TEMPLATE]: MutationHelpers.set,
  [types.EDIT_TEMPLATE]: MutationHelpers.update,
  [types.DELETE_TEMPLATE]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
